<template>
  <footer class="footer dot-pattern">
    <div class="content">
      <p>&copy; 2024 - All rights reserved</p>
      <p>Designed and Developed by Loïc Tisseyre</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>
.footer {
  width: 100%;
  background-color: var(--color-background);
  color: var(--color-paragraph);
  padding-top: 100px;
  text-align: center;
}

</style>